import React, { useState } from 'react';
import SEO from '../../components/SEO';
import imageIllustrationMortageFirstSection from '../../assets/images/illustrations/RemortgagePage/remortgage-first-section.png';
import imageIllustrationMortageFirstSectionMobile from '../../assets/images/illustrations/RemortgagePage/remortgage-first-section-mobile.png';
import imageTestimonialsIndi1x from '../../assets/images/illustrations/indi-testimonials1x.png'
import imageTestimonialsIndi2x from '../../assets/images/illustrations/indi-testimonials2x.png'
import imageTestimonialsJenny1x from '../../assets/images/illustrations/jenny-testimonials1x.png'
import imageTestimonialsJenny2x from '../../assets/images/illustrations/jenny-testimonials2x.png'
import imageTestimonialsBrijesh1x from '../../assets/images/illustrations/brijesh-testimonials1x.png'
import imageTestimonialsBrijesh2x from '../../assets/images/illustrations/brijesh-testimonials2x.png'
import imageTestimonialsBella1x from '../../assets/images/illustrations/bella-testimonials1x.png'
import imageTestimonialsBella2x from '../../assets/images/illustrations/bella-testimonials2x.png'
import CustomHeadingSection from '../../components/CommonSections/CustomHeadingSection';
import SectionWrapper from '../../components/CommonSections/SectionWrapper';
import useWindow from '../../hooks/useWindow';
import FAQSection from '../../components/FAQSection';
import HeaderLeftContent from '../../components/CommonSections/CustomHeadingSection/HeaderLeftContent';
import { Link } from 'gatsby';
import useSliderHeight from '../../hooks/useSliderHeight';
import CustomImage from '../../components/Image/Image';
import { useMemo } from 'react';
import loadable from '@loadable/component';

const ModalsFlow = loadable(() => import("../../components/Modals/ModalsFlow"))
const SliderSection = loadable(() => import("../../components/CommonSections/SliderSection"))
const DownloadAppSection = loadable(() => import("../../components/CommonSections/DownloadAppSection"))
const ContactWidget = loadable(() => import("../../components/ContactWidget"))

const FAQItems = [
  {
    title: 'How does Sprive help with remortgaging?',
    description: (
      <>
        If you’re using the Sprive app to mortgage overpayments, then you’ll unlock the ability
        for our smart technology to compare your mortgage against the market every day and see
        live personalised deals within the app. At the right time, we’ll connect you to a mortgage
        expert who will help you secure the right deal for you for free. It’s a fast way to switch
        as we already know a lot about you and your mortgage. By switching via the app flow, we’ll
        seamlessly help you make mortgage overpayments on your new mortgage and be able to automatically
        take into account any early repayment charges associated with your new mortgage
        <br />
        <br />
        If you’re not a user of the app, we can still help you. Simply book an appointment
        with an expert via our website.
      </>
    ),
  },
  {
    title: 'What is Remortgaging?',
    description: (
      <>
        Remortgaging is the process of switching your current mortgage to a new lender or negotiating
        a new deal with your current lender. The main reasons for remortgaging are to save money on
        your mortgage payments, release equity from your property, or change the terms of your mortgage
        agreement. Read our <Link to="/blog/20-reasons-to-remortgage-with-sprive/">blog</Link> here to learn
        more on the benefits of remortgaging with us as a Sprive app user.
      </>
    ),
  },
  {
    title: 'What is the difference between mortgage and remortgage?',
    description: (
      <>
        A mortgage is a loan you take out to buy a property, while a remortgage is when you switch your
        existing mortgage to a new one. When you first take out a mortgage, it is typically for a fixed term,
        usually between 2 and 5 years. After this initial period, you can either stay on the same mortgage deal
        or switch your current deal to a new deal by remortgaging.
      </>
    ),
  },
  {
    title: 'Is it cheaper to remortgage or get a new mortgage?',
    description: (
      <>
        Remortgaging can be cheaper than getting a new mortgage, depending on the interest rates and fees involved.
        When deciding between remortgaging or getting a new mortgage, consider the following factors:
        <br />
        <br />
        <ul>
          <li>
            Compare the interest rates and fees involved to determine which option is cheaper
          </li>
          <li>
            Consider the length of the mortgage term
          </li>
          <li>
            Look at any penalties for early repayment
          </li>
          <li>
            Think about your long-term financial goals
          </li>
        </ul>
        <br />
        Remember to shop around and compare deals to find the best option for your individual circumstances.
      </>
    ),
  },
  {
    title: 'Why should I remortgage?',
    description: (
      <>
        People remortgage for various reasons, including securing better interest rates across the
        mortgage market, accessing equity to fund home improvements, or switching to a different
        type of mortgage (for example, away from a fixed rate deal to one with the lender's standard
        variable rate). Additionally, it can be a way to consolidate debt into a single payment. For
        more information, read our blog <Link to="/blog/when-should-you-switch-mortgage/">here</Link>.
      </>
    ),
  },
  {
    title: 'What are the key factors I need to consider when I remortgage?',
    description: (
      <>
        When remortgaging, consider factors like fees, interest rates, mortgage term length, and any
        penalties for early repayment. It's also important to think about your long-term financial goals
        and whether remortgaging aligns with them and the purpose behind it.
        <br />
        <br />
        For example, if you’re considering releasing equity from your home to finance a second
        property, it's essential to evaluate the investment potential of the money you release.
        Additionally, using the equity for home improvements could increase your property's value
        and yield a better return on investment.
        <br />
        <br />
        When releasing equity, it's crucial to consider the impact on your overall financial situation,
        including the potential for higher mortgage payments and longer repayment terms. Before
        deciding to remortgage, it's advisable to speak to an independent financial advisor to ensure
        that you're making an informed decision.
      </>
    ),
  },
  {
    title: 'What are the expenses associated with a remortgage?',
    description: (
      <>
        When you decide to remortgage your property, there are several fees associated with the
        process. Here are some of the common fees you may encounter:
        <br />
        <br />
        <ol>
          <li>
            Valuation fee: This is the cost of having your property valued by a surveyor to
            determine its current market value.
          </li>
          <li>
            Arrangement fee: This is the fee charged by your lender for setting up your new
            mortgage. It can be a flat fee or a percentage of your loan amount.
          </li>
          <li>
            Legal fees: You will need to hire a solicitor or conveyancer to handle the legal
            aspects of your remortgage.
          </li>
          <li>
            Early repayment charges: If you are remortgaging before the end of your current
            mortgage term, you may be charged an early repayment fee.
          </li>
          <li>
            Exit fee: Your current lender may charge you an exit fee for leaving their mortgage
          </li>
          <li>
            Broker fee: If you use a mortgage broker to find your new mortgage deal, they may
            charge a fee for their services.
          </li>
        </ol>
        <br />
        There may be additional costs involved and that’s why it's important to factor them into your
        budget. For more information, read our blog <Link to="/blog/remortgaging-costs-understanding-the-expenses-involved/">here</Link>.
      </>
    ),
  },
  {
    title: 'Am I eligible for a remortgage?',
    description: (
      <>
        You’re typically eligible to remortgage 6 months before your existing deal expires.
        Once your deal expires, your interest rate interest rate will revert to the Standard
        Variable Rate (SVR) of the current mortgage lender.  If you switch within your deal
        period, you’re likely be charged an early repayment charge.
        <br />
        <br />
        When considering a remortgage, your eligibility will depend on factors such as your credit
        score, income, and equity in your home. Lenders will also look at your existing mortgage and
        debts to ensure you can manage repayments. Eligibility criteria may vary among lenders, so
        it's important to compare offers to find the best deal for your situation.
      </>
    ),
  },
  {
    title: 'Should I remortgage with my current lender or a new mortgage lender?',
    description: (
      <>
        When it comes to deciding whether to remortgage with your current lender or
        switch to a new mortgage lender, there are benefits to both. At Sprive, we have
        access to mortgage experts who can help you with both. The benefits of sticking
        with your existing lender include:
        <br />
        <br />
        <ul>
          <li>
            <span>Interest Rates:</span> If you have an established relationship with your lender, they may
            offer you a more competitive interest rate than a new lender would.
          </li>
          <li>
            <span>Fees & Charges:</span> If you have a good payment history and a strong credit score,
            you may be able to negotiate with your current lender to waive or reduce certain fees,
            such as arrangement or valuation fees.
          </li>
          <li>
            <span>Simplified Application Process:</span> You may be able to avoid a credit check or a
            full affordability assessment, as your lender already has access to your payment
            history and financial information.
          </li>
        </ul>
        <br />
        On the other hand, benefits of choosing a new lender include:
        <br />
        <br />
        <ul>
          <li>
            <span>Interest Rates:</span> You may find better deals and potentially lower interest rates
            if you shop across multiple lenders to ensure that you're getting the best possible deal.
          </li>
          <li>
            <span>More Variety:</span> If you're looking for a particular type of mortgage product,
            such as a flexible mortgage or an interest-only mortgage, you may need to look beyond
            your current lender to browse mortgage deals and find the right product for you.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: 'What are the risks of remortgaging?',
    description: (
      <>
       Remortgaging can have its downsides, including:
       <br />
       <br />
       <ul>
        <li>
          Fees and costs involved: Remortgaging can be more expensive than expected due to fees
          and costs like arrangement fees, valuation fees, legal fees, and exit fees.
        </li>
        <li>
          Longer repayment term: Remortgaging to release equity or consolidate debt may extend
          the repayment term, resulting in paying more interest over the life of the mortgage.
        </li>
        <li>
          Risk of negative equity: If the value of your property decreases, you may end up in
          negative equity, making it difficult to remortgage or sell your property.
        </li>
        <li>
          Impact on credit score: Remortgaging can affect your credit score, making it
          harder to obtain credit in the future.
        </li>
        <li>
          Risk of repossession: If you use your property as security for the loan and can't
          keep up with repayments, you could be at risk of losing your home.
        </li>
       </ul>
       <br />
       It's important to consider the potential downsides and assess whether remortgaging is the best option for your financial situation.
      </>
    ),
  },
  {
    title: 'Do I pay less when I remortgage?',
    description: (
      <>
        Remortgaging can potentially result in lower monthly payments or a lower interest rate,
        but it depends on the specific deal you can secure.
        <br />
        <br />
        You may be able to save money by remortgaging to a new fixed or variable rate deal at a
        lower interest rate, but it's important to compare offers and consider the fees and costs
        involved depending on whether you’re sticking with your current lender or choosing a new lender.
        <br />
        <br />
        If you're remortgaging to release equity or consolidate debt, consider the impact of a longer
        repayment term and increased interest costs over the life of the mortgage.

      </>
    ),
  },
  {
    title: 'How does my credit score affect my remortgage?',
    description: (
      <>
        Your credit score will play a significant role in whether or not you are eligible for a
        remortgage, as well as the interest rate you are offered. Lenders will use your credit score
        to assess your risk as a borrower and determine whether or not to offer you a remortgage.
        <br />
        <br />
        However, if you have bad credit, you may find it challenging to remortgage. But it can actually
        help rebuild your credit score, as it can consolidate debts, provide better credit terms,
        and lower monthly payments over time. Having bad credit doesn't mean you cannot remortgage,
        and it's worth exploring your options to see if it could benefit your financial situation.
      </>
    ),
  },
];

function RemortgagePage() {
  const overrideCalendlyURL = 'https://calendly.com/teamsprive/chat-to-our-expert-instagram';
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [dealsContactModal, setDealsContactModal] = useState(true)
  const { isDesktop, isMobile } = useWindow();
  const sliderHeight = useSliderHeight("611px", "198px", "234px")

  const sliderItems = useMemo(() => ([
    {
      image: isMobile ? imageTestimonialsJenny1x : imageTestimonialsJenny2x,
      description: `I recently remortgage with Sprive and I couldn't be happier
        with the experience. From start to finish, they made the process incredibly
        simple and efficient, surpassing my expectations in every way.`,
      person: 'Jenny Holden',
      personInfo: 'PR'
    },
    {
      image: isMobile ? imageTestimonialsIndi1x : imageTestimonialsIndi2x,
      description: `Thanks to Sprive I was able to both easily manage repayments,
        but also find great deals for remortgaging, which Sprive took the hassle out of too.`,
      person: 'Indi Rai',
      personInfo: 'Head of Marketing'
    },
    {
      image: isMobile ? imageTestimonialsBrijesh1x : imageTestimonialsBrijesh2x,
      description: `It’s coming to my time to remortgage. Sprive shows you live deals
        when you’re ready to remortgage, it's really hassle-free so you don’t need to
        fill out lots of forms and details because it has all of your mortgage details already.`,
      person: 'Brijesh Pujara',
      personInfo: 'Engineer'
    },
    {
      image: isMobile ? imageTestimonialsBella1x : imageTestimonialsBella2x,
      description: `The app is really user friendly and easy to use. It’s great to
        be able to chip away at my mortgage and see the impact that’s having on the overall length.`,
      person: 'Bella Melvin',
      personInfo: 'Solicitor'
    }
  ]), [isMobile])

  return (
    <>
      <SEO title="Compare remortgage deals with Sprive" description="Compare personalised remortgage deals, in just a few clicks. Remortgaging with Sprive could save you up to £300* on your mortgage every month."/>
      <div className="common-page remortgage-page">
        <div className="remortgage-page__heading-container">
          <SectionWrapper isFirstSection={true}>
            <CustomHeadingSection
              leftContent={
                <HeaderLeftContent
                  title={
                    <>
                      Remortgage,
                      {isDesktop ? <br /> : ' '}
                      hassle-free
                    </>
                  }
                  image={isMobile ? imageIllustrationMortageFirstSectionMobile : null}
                  imageReversed
                  description="Remortgaging with Sprive could save you up to £300* on your mortgage every month."
                  buttonText="Get Free Quote"
                  handleClick={() => {
                    setIsContactModalOpen(true)
                    setDealsContactModal(false)
                  }}
                  useWidget
                />
              }
            />
            {!isMobile && (
              <div className="main-image-container">
                <CustomImage
                    className="w-100 main-image"
                    src={imageIllustrationMortageFirstSection}
                    alt="main image"
                  />
              </div>
            )}
          </SectionWrapper>
        </div>
        <div style={{minHeight: isMobile ? "650px" : "611px"}}>
          <SliderSection
            sliderItems={sliderItems}
            customHeight={sliderHeight}
          />
        </div>
        <div className='faq-section-title'>
          <FAQSection
            FAQItems={FAQItems}
            title={
              <>
                Have a question about remortgaging?
                {isDesktop ? <br /> : ' '}
                Check out our FAQ’s
              </>
            }
          />
        </div>
        <div style={{minHeight: isMobile ? "382px" : "429px"}}>
          <DownloadAppSection />
        </div>
        <ContactWidget
          handleClick={() => {
            setIsContactModalOpen(true)
            setDealsContactModal(false)
          }}
          title="Speak to a mortgage expert for FREE"
        />
        <ModalsFlow
          isFirstOpened={isContactModalOpen}
          setIsFirstOpened={(state) => setIsContactModalOpen(state)}
          dealsContactModal={dealsContactModal}
          overrideCalendlyURL={overrideCalendlyURL}
        />
      </div>
    </>
  );
}

export default RemortgagePage;
